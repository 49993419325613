<template>
	<!-- 仓库资料 -->
	<div class="warehouseData">
		<div class="table">
			<div class="table-operation">
				<el-button type="warning" icon="el-icon-plus"
					@click.stop="dialogVisible = true, form = {type:'DQP7'}">新增</el-button>
				<el-button type="info" icon="el-icon-refresh-left" @click.stop="loading = true, getList()"></el-button>
				<span style="color:#F56C6C;padding-left:20px">目前仅支持“<a href="https://u.jd.com/iOYCBoa"
						target="_blank">深圳大趋智能</a>”厂家的打印机设备，该厂家打印机同时支持语音播报</span>
			</div>
			<el-table :data="tableData" :total="total" v-loading="loading">
				<el-table-column label="设备类型" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.type == 'DQP7'">大趋智能P7</span>
						<span v-if="scope.row.type == 'DQP8'">大趋智能P8</span>
					</template>
				</el-table-column>
				<el-table-column label="设备名称" prop="name" align="center"></el-table-column>
				<el-table-column label="设备SN" prop="sn" align="center"></el-table-column>
				<el-table-column label="设备KEY" prop="key" align="center"></el-table-column>

				<el-table-column label="创建时间" prop="created_at" align="center"></el-table-column>
				<el-table-column label="操作" align="center" width="160">
					<template slot-scope="scope">
						<el-button type="danger" size="mini" @click.stop="removeFn(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!-- 新增 -->
		<el-dialog :title="'新增'" :visible.sync="dialogVisible" :close-on-click-modal="false" v-el-drag-dialog
			width="30%">
			<el-form ref="form" :model="form" label-width="120px">
				<el-form-item label="设备名称" required>
					<el-input v-model="form.name" placeholder="请输入设备名称"></el-input>
				</el-form-item>
				<el-form-item label="设备类型" required>
					<el-radio-group v-model="form.type">
						<el-radio label="DQP7">大趋智能P7</el-radio>
						<el-radio label="DQP8">大趋智能P8</el-radio>
					</el-radio-group>
					<p>一般在设备机身上有标注设备型号 P7/P8</p>
				</el-form-item>
				<el-form-item label="设备SN" required>
					<el-input v-model="form.sn" placeholder="请输入设备SN"></el-input>
					<span>一般在设备机身上有标注设备编号（SN）</span>
				</el-form-item>
				<el-form-item label="设备KEY" required>
					<el-input v-model="form.key" placeholder="请输入设备KEY"></el-input>
					<span>一般在设备机身上有标注设备绑定码（KEY）</span>
				</el-form-item>

			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click.stop="dialogVisible = false">取 消</el-button>
				<el-button type="warning" @click.stop="confirmFn">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				tableData: [],
				loading: true,
				// 新增、编辑弹窗
				dialogType: 1, //1新增2编辑
				dialogVisible: false,
				plDialogVisible: false,
				form: {
					type: 'DQP7'
				},
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				this.$http.post(`/erp/v1/device/lst`, {}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 确定
			confirmFn() {
				let url = '/erp/v1/device/add'
				this.$http.post(`${url}`, this.form).then((res) => {
					const {
						code,
						msg
					} = res.data
					if (code == 200) {
						this.$message.success(msg);
						this.dialogVisible = false
						this.getList()
					} else {
						this.$message.error(msg);
					}
				});
			},

			// 删除
			removeFn(item) {
				this.$confirm('确定要删除设备【' + item.name + '】吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post(`/erp/v1/device/delete`, {
						id: item.id
					}).then((res) => {
						const {
							code,
							msg
						} = res.data
						if (code == 200) {
							this.$message.success(msg);
							this.getList()
						} else {
							this.$message.error(msg);
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			}
		},
	}
</script>

<style lang="scss" scoped></style>